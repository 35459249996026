import * as React from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import Logo from "../../static/logo_pendu.svg"

const NotFoundPage = () => {
  return (
    <MainContainer>
        <LeftContainer>
            <a><img src={Logo} alt='bijour' style={{maxWidth:'258px',height:'571px',opacity:0.9,top:'0px'}}/></a>
            <h1 style={{textAlign:'center'}}>404 error</h1>
            <div><Link to="/" style={{textDecoration:'underline'}}>{"> Get back to homepage"}</Link></div>
        </LeftContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
    margin:0;
    padding:0;
    display:flex;
    flex-direction:row;
    min-height:933px;
    border-top:solid #2B2B2B 1px;
    padding:0px;
    background-color:#222222;
    @media (max-width: 724px) {
        flex-direction:column;
        align-items:center;
    }
`

const LeftContainer = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    align-items:center;
    @media (max-width: 724px) {
        width:100%;
        min-height:633px;
        padding:0px 0px 80px 0px;
    }
`

export default NotFoundPage

export const Head = () => {
    return <meta name="robots" content="noindex, nofollow" />
}